/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can 
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

// Use this variable to set up the common and page specific functions. If you 
// rename this variable, you will also need to rename the namespace below.
var Bace = {
	
	// All pages
	common: {
		init: function() {
		
			/*$(window).scroll(function() {
				var stickyScroll = $(window).scrollTop();
				if (stickyScroll > 1) {
					$('.banner').addClass('banner_sticky');
				} else {
					$('.banner').removeClass('banner_sticky');	
				}			
			});*/
		
		
			$.fn.closeCanvas = function() {
				var $width = this.width();
				if ( $width > 600 ) {
					$('body').removeClass('canvas-slid');
					$('body').removeAttr('style');
					$('#nav-mobile').removeClass('in');
					$('#nav-mobile').removeClass('canvas-slid');
					$('#nav-mobile').removeAttr('style');				
				}
			};
			
			$(window).resize(function() {
				$('.site_info .container').closeCanvas();
			});
					
			$(window).click(function() {
				if ( $('.jHoursGet').hasClass('open') ) {
					$('.jHoursGet').removeClass('open');
					$('.jHoursMore').removeClass('open');
					$('.jHoursMore').slideUp();
				}
			});
			
			$('.jHoursGet').click(function(event) {
				event.stopPropagation();
				$(this).toggleClass('open');
				$('.jHoursMore').toggleClass('open');
				if ( $('.jHoursMore').hasClass('open') ) {
					$('.jHoursMore').slideDown();
				} else {
					$('.jHoursMore').slideUp();
				}
			});
			
			// SMOOTH SCROLL # LINKS
			$('a[href*="#"]')
				.not('[href="#"]')
				.not('[href="#0"]')
				
				.click(function(event) {
					if ( location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname ) {
						var target = $(this.hash);
						target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
						if (target.length) {
							event.preventDefault();
							$('html, body').animate({ scrollTop: target.offset().top }, 1000, function() {
								var $target = $(target);
								$target.focus();
								if ($target.is(":focus")) {
									return false;
								} else {
									$target.attr('tabindex','-1');
									$target.focus();
								}
							});
						}
					}	
				});

			// GALLERY CAPTIONS
			$(".jsCaption").each(function() {
				
				var imageCaption = $(this).attr("alt");
								
				if ( $(this).hasClass("img--right") ) {
					$(this).removeClass("img--right");
					$(this).wrap( '<div class="img-captioned img--right"></div>' );	
					$("<span>"+imageCaption+"</span>").addClass('caption').insertAfter(this);
					return;
				}

				if ( $(this).hasClass("img--left") ) {
					$(this).removeClass("img--left");
					$(this).wrap( '<div class="img-captioned img--left"></div>' );	
					$("<span>"+imageCaption+"</span>").addClass('caption').insertAfter(this);
					return;
				}

				$(this).wrap( '<div class="img-captioned"></div>' );	
				$("<span>"+imageCaption+"</span>").addClass('caption').insertAfter(this);
				return;
				
			});
			
		}
	},
	
	page_front: {
		init: function() {
			
			$('.slider_jumbo').slick({
				autoplay: true,
				autoplaySpeed: 6000,
				arrows: false,
				fade: true,
				infinite: true,
				mobileFirst: true,
				pauseOnFocus: false,
				pauseOnHover: false,
				slidesToShow: 1,
				slidesToScoll: 1,
				slide: 'div'
			});	
			
		}
	},	
			
	has_youtube: {
		init: function() {
		// JavaScript to be fired on the home page

			/* Light YouTube Embeds by @labnol */
			/* Web: http://labnol.org/?p=27941 */

			function labnolThumb(id) {
				var play = '<div class="play"><svg class="icon"><use xlink:href="/_/images/layout/site_sprite.svg#play"></use></svg></div>',
					thumb = '<img loading="lazy" width="480" height="300" src="/_/images/video/ID.jpg" alt="Click to Play Video.">';

				return play + thumb.replace("ID", id);
			}

			function labnolIframe() {
				var iframe = document.createElement("iframe");
				var embed = "https://www.youtube.com/embed/ID?autoplay=1?rel=1";
				iframe.setAttribute("src", embed.replace("ID", this.dataset.id));
				iframe.setAttribute("frameborder", "0");
				iframe.setAttribute("allow", "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture");
				iframe.setAttribute("allowfullscreen", "1");
				this.parentNode.replaceChild(iframe, this);
			}

			//document.addEventListener("DOMContentLoaded", function() {
				var div, n,
					v = document.getElementsByClassName("youtube-player");
				for (n = 0; n < v.length; n++) {
					div = document.createElement("div");
					div.setAttribute("data-id", v[n].dataset.id);
					div.innerHTML = labnolThumb(v[n].dataset.id);
					div.onclick = labnolIframe;
					v[n].appendChild(div);
				}
			//});

			
			
		}
	}
	
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
	fire: function(func, funcname, args) {
		var namespace = Bace;
		funcname = (funcname === undefined) ? 'init' : funcname;
		if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
			namespace[func][funcname](args);
		}
	},
	loadEvents: function() {
		UTIL.fire('common');
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/),function(i,classnm) {
				UTIL.fire(classnm);
			});
	}
};

$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.